(function (win, $) {
    'use strict';
    var fp = (win.fp = typeof win.fp !== 'undefined' ? win.fp : {}),
        home = (fp.home = typeof fp.home !== 'undefined' ? fp.home : {});

    fp.home = (function () {
        return {
            init: function () {
                $('.homepage-link').addClass('selected');
           }
        };
    })();
})(window, $);